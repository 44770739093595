export default function ProductCard({name, desc, price, id, img}){
    return(
        <div className="product-card">
            <h3 style={{marginLeft:"20px"}}><a style={{textDecoration:"none"}} href={`/product/${id}`}>{name}</a></h3>
            <p>{desc}</p>
            <br/>
            <img src={img} style={{marginBottom:"7px"}} alt={name}/>
            <b><p style={{textAlign:"center", fontSize:"20px", marginTop:"20px"}}>₹{price}</p></b>
        </div>
    )
}