import axios from "axios";
import { useEffect, useState } from "react";
import Loading from "./Loading";
import Cookies from 'universal-cookie';
import { useNavigate, useParams } from "react-router-dom";
export default function Product(){
    const [isLoading, setIsLoading] = useState(true);
    const {id} = useParams();
    const [index, setIndex] = useState(0);
    const navigate = useNavigate();
    const [product, setProduct] = useState(null);
    const cookie = new Cookies();
    useEffect(() => {
        if(!id){
            navigate('/');
        }
        (async () => {
            try{
                const data = await axios.post('/api/getProductInfo', {id:id});
                setProduct(data.data.data);
                setIsLoading(false);
            }catch(e){
                console.log(e)
                navigate('/');
            }  
        })();
    }, []);
    return(
        <>
            {isLoading ? <Loading/> 
                : <div className="product">
                    <div className="product-img">   
                        <img src={product.pictures[index]} alt={product.name}/>
                    </div>
                    <div className="product-navigator">
                        <input type="button" value={"<"} className="product-navigate-button" onClick={() => {
                            if(index === 0){
                                return;
                            }
                            setIndex(index-1);
                        }}/>
                        {index+1} of {product.pictures.length}
                        <input type="button" value={">"} className="product-navigate-button" onClick={() => {
                            if(index+1 === product.pictures.length){
                                return;
                            }
                            setIndex(index+1);
                        }}/>
                        <br/>
                    </div>
                    <div className="product-title">
                            <h3>{product.name}</h3>
                    </div>
                    <br/>
                    <div className="product-description">
                        <p>{product.description}</p>
                    </div>
                    <ul className="product-specifications">
                        {product.specifications.map(spec => {
                            return <li key={spec}>{spec}</li>
                        })}
                    </ul>
                    <div className="order">
                        <input type="number" defaultValue={'0'} id="order-num" min={'0'} max={'5'}/>
                        <input type="button" value={'Order'} onClick={async() => {
                            if(document.getElementById('order-num').value == 0){
                                alert('You need to enter a number');
                            }else{
                                if(!cookie.get('token')){
                                    alert('You need to login before ordering!')
                                    navigate('/login');
                                }
                                const res = await axios.post('/api/order', {token:cookie.get('token'), id:product.id, quantity:document.getElementById('order-num').value});
                                if(res.data.statusToken){
                                    navigate('/result?code=' + res.data.statusToken);
                                }
                            }
                        }}/>
                    </div>
                    
                </div>
            }

        </>
    );
}
