import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";

export default function Login(){
    const cookies = new Cookies();
    const navigate = useNavigate();
    async function getToken(){
        try{
            const res = await axios.post('/api/register', {username:document.getElementById('username').value, password:document.getElementById('password').value, address:document.getElementById('address').value, email:document.getElementById('email').value, phoneNum:document.getElementById('phoneno').value});
            if(res.data.token){
                cookies.set('token', res.data.token, {maxAge:60*60*12});
                if(res.data.isAdmin){
                    navigate('/admin');
                }else{
                    navigate("/");
                }
            }else{
                document.write('incorrect password');
            }
        }catch(e){
            console.log(e);
            navigate('/');
        }
        
    };
    useEffect(() => {
        if(cookies.get('token')){
            navigate('/');
        }
    });
    return(
        <>
            <div id="register-page">
                <form>
                    <h1>register</h1>
                    <h4><label for='username'>username:</label></h4>
                    <input type="text" className='login-input' name="username" id="username"/>
                    <h4><label for='username'>password:</label></h4>
                    <input type="text" className='login-input' name="password" id="password"/>
                    <h4><label for='username'>email:</label></h4>
                    <input type="text" className='login-input' name="email" id="email"/>
                    <h4><label for='username'>Address:</label></h4>
                    <input type="text" className='login-input' name="address" id="address"/>
                    <h4><label for='username'>Phone Number:</label></h4>
                    <input type="text" className='login-input' name="phoneNum" id="phoneno"/>
                    <br/>
                    <input type="button" name="submit" value={"Login"} onClick={getToken}/>
                </form>
            </div>
        </>
    );
}