import axios from "axios"
import { useEffect, useState } from "react";
import Loading from "./Loading";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie"

export default function Admin(){
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [isOrderStatusChanged, setOrderStatusChanged] = useState([]);
    useEffect(() => {
        if(!cookies.get('token')){
            navigate('/');
        }else{
            (async() => {
                try{
                    console.log(cookies.get('token'));
                    const res = await axios.post('/api/getOrders', {token:cookies.get('token')});
                    setOrders(res.data.data);
                    setIsLoading(false);
                }catch(e){
                    console.log(e);
                    navigate('/');
                }
            })();
        }
    }, []);
    return(
        <>
            {isLoading ? <Loading/> : 
                <div className="admin-page">
                    <table className="admin-table">
                        <tr>
                            <th>Order ID</th>
                            <th>Image</th>
                            <th>Product Name</th>
                            <th>Customer Name</th>
                            <th>Customer address</th>
                            <th>Quantity</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Change status</th>
                            {isOrderStatusChanged ? <th>Confirm</th> : null}
                        </tr>   
                        {orders && orders.map(order => {
                            return( <tr>
                                <td id={order.id}><input type="button" value="Show Order ID" onClick={() => {
                                    document.getElementById(order.id).innerText = order.id;
                                }}/></td>
                                <td><img src={order.product.pictures[0]} alt={order.product.name} style={{width:'100%'}}/></td>
                                <td>{order.product.name}</td>
                                <td>{order.user.username}</td>
                                <td>{order.user.address}</td>
                                <td>{order.quantity}</td>
                                <td>{order.user.email}</td>
                                <td>{order.status === 0 ? 'Order is being prepared' : order.status === 1 ? "Order is under courier" : order.status === 2 ? "Order has been delivered" : order.status === 4 ? "Order has been canceled" : "There seems to be an issue with the order" }</td>
                                {order.status === 2 || order.status === 4 ? null : <td><select name="OrderNo" defaultValue={''} id="order-new-status" onChange={() => {
                                    setOrderStatusChanged([...isOrderStatusChanged, order.id])
                                }}> 
                                        <option value="" disabled hidden>Choose here</option>
                                        {order.status === 0 ? null : <option value={"0"}>Order is being prepared</option>}
                                        {order.status === 1 ? null : <option value={"1"}>Order is under courier</option>}
                                        {order.status === 2 ? null : <option value={"2"}>Order has been delivered</option>}
                                    </select>
                                </td>}
                                <td>{isOrderStatusChanged.indexOf(order.id) != -1  && order.status !== 2 ? <input type="button" value={'Confirm'} onClick={async() => {
                                    try{
                                        const res = await axios.post('/api/setOrderStatus', {token:cookies.get('token'), id:order.id, status:document.getElementById('order-new-status').value});
                                        res.data.status === 200 ? window.location.reload() : document.write('error');
                                    }catch(e){

                                    }
                                }}/> : null}</td>
                            </tr>
                            );
                        })}
                    </table>
                </div>
            }
        </>
    );
}