import axios from "axios";
import { useEffect, useState } from "react";
import ProductCard from "./ProductCard";
import {useNavigate} from 'react-router-dom';
import Loading from "./Loading";
export default function Home(){
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [products, setProducts] = useState({data:[]})
    const [csrf, setCSRF] = useState();
    useEffect(() => {
        (async () => {
            try{

            }catch(e){
                console.log(e);
                navigate('/');
            }
            const res = await axios.get('/api/getProducts', {headers:{'x-csrf-token':csrf}});
            console.log(res);
            setProducts(res.data);
            setIsLoading(false);
        })();
    }, []);
    console.log(products.data);
    return(
        <>
        {isLoading ? <Loading/> :
            <div>
                <h1 style={{textAlign:'center'}}>Our products:</h1>
                    {products.data.map((product) => {
                        return <ProductCard name={product.name} price={product.price} desc={product.desc} img={product.pictures[0]} id={product.id}/>
                    })}
            </div>
        }   
        </>
    );
}