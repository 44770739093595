import { NavLink } from "react-router-dom";
import Cookies from 'universal-cookie';

export default function Navbar(){
    const cookies = new Cookies();
    return(
        <>
            <nav class='navbar'>
                <ul className="navbar-ul">
                    <li style={{float:"left", backgroundColor:"#e59500", color:"#02040f"}}>Felico</li>
                    <li><NavLink to="/">Home</NavLink></li>
                    <li>{cookies.get('token') ? <NavLink to="/dashboard">Dashboard</NavLink> : <NavLink to="/login">Login</NavLink>}</li>
                </ul>
            </nav>
        </>
    );
}