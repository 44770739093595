import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Register from './components/Register';
import Home from './components/Home';
import Login from './components/Login';
import Product from './components/Product';
import Layout from './components/Layout';
import ForwardToHome from './components/ForwardToHome';
import Admin from './components/Admin';
import Result from './components/Result';
import UserDashboard from './components/UserDashboard';

export default function App(){
  return(
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout/>}>
          <Route element={<Home/>} index/>
          <Route path='login' element={<Login/>}/>
          <Route path='register' element={<Register/>}/>
          <Route path='product/:id' element={<Product/>}/>
          <Route path='admin' element={<Admin/>}/>
          <Route path='result' element={<Result/>}/>
          <Route path='dashboard' element={<UserDashboard/>}/>
          <Route path='*' element={<ForwardToHome/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}