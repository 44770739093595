import { TailSpin } from "react-loader-spinner";

export default function Loading(){
    return(
        <div className="loading">
            <TailSpin
                visible={true}
                height="100"
                width="80"
                color="#4fa94d"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass="loading-spinner"
            />
        </div>
    );
}