import axios from "axios";
import { useEffect, useState } from "react";
import {useNavigate} from 'react-router-dom';
import Cookies from 'universal-cookie';
import Loading from "./Loading";

export default function UserDashboard(){
    const cookies = new Cookies();
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        (async() => {if(!cookies.get('token')){
            navigate('/login');
        }else{
            try{
                const res = await axios.post('/api/getUserOrders', {token:cookies.get('token')});
                setOrders(res.data.data);   
                setIsLoading(false);
                console.log(res.data.data);    
            }catch(e){
                console.log(e);
                navigate('/');
            }
        }})();
    }, []);
    return(
        isLoading ? <Loading/> : <div className="user-orders">
            <h1>Your Orders:</h1>
            {orders.map(order => {
                return (
                    <>
                    <div key={order.id} className="user-order"><h2>Order ID: {order.id}</h2><img src={order.product.pictures[0]}/><h4>{order.product.name}</h4><h5>Status: {order.status === 0 ? 'Order is being prepared!' : order.status === 1 ? 'Order is under courier!' : order.status === 2 ? 'Order has been delivered!' : order.status === 4 ? "Order has been cancelled" : 'Unknown' }</h5>{order.status > 1 ? null : <input type="button" value={'Cancel'} onClick={async() => {
                        setIsLoading(true);
                        const res = await axios.post('/api/cancelOrder', {token:cookies.get('token'), id:order.id});
                        window.location.reload();
                    }}/> }</div>
                    </>
                )
            })}
        </div>
    );
}