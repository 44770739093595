import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Loading from "./Loading";
import axios from "axios";

export default function Result(){
    const [search, setSearch] = useSearchParams();
    const [success, setStatus] = useState(0);
    const [message, setMessage] = useState('');
    const [isLoading,setIsLoading] = useState(true);
    useEffect(() => {
            (async() => {
                try{
                    if(search.get('code')){
                        const res = await axios.post('/api/decodeResult', {id:search.get('code')});
                        setMessage(res.data.message);
                        setIsLoading(false);
                    }else{
                        setStatus(2);
                    }
                }catch(e){
                    setStatus(1);
                }
        })();
    });
    return(
        <>
            {isLoading ? <Loading/> : <div className="result">
                    {message}
                </div>}
        </>
    );
}